import {useEffect, useRef, useState} from "react";
import WaveSurfer from "wavesurfer.js";
import {useChat} from "./ChatProvider";
import {Row} from "./Row";
import {CountDownTimer} from "./Timer";

type UserChatProps = {
  chat: string;
  index: number;
  audioChunks: Blob[];
  onEndTime?: () => void;
};

const UserChat = ({chat, index, audioChunks, onEndTime}: UserChatProps) => {
  const {chats} = useChat();
  const isTranscribingQuestion = index === chats.length - 1;

  const waveSurfer = useRef<WaveSurfer | null>(null);

  const [showTimer, setShowTimer] = useState<boolean>(false);
  const [renderTimer, setRenderTimer] = useState(true);

  useEffect(() => {
    if (audioChunks.length > 0 && waveSurfer) {
      const audioBlob = new Blob(audioChunks, {type: "audio/wav"});
      waveSurfer.current?.loadBlob(audioBlob);
    }
  }, [audioChunks]);

  useEffect(() => {
    setRenderTimer(true);
  }, [isTranscribingQuestion]);

  useEffect(() => {
    waveSurfer.current = WaveSurfer.create({
      container: `#waveform-${index}`,
      waveColor: "black",
      progressColor: "orange",
      cursorColor: "transparent",
      height: "auto",
      dragToSeek: false,
    });
  }, []);

  return (
    <Row
      classNames={`bg-[#785DD2] mr-4 text-white-900 max-w-3/4 min-w-3/4 py-3 px-4 rounded-lg text-sm font-bold justify-center flex-col`}
    >
      <p>{chat}</p>
      <Row
        classNames={`w-full ${
          isTranscribingQuestion ? "bg-white-900" : "transparent"
        } justify-center rounded-lg mt-4`}
      >
        <div
          style={{display: isTranscribingQuestion ? "block" : "none"}}
          className={`h-12 w-1/2`}
          id={`waveform-${index}`}
        ></div>
        {isTranscribingQuestion && renderTimer && (
          <div
            style={{
              marginLeft: 10,
            }}
          >
            <CountDownTimer
              time={+process.env.REACT_APP_SINGLE_QUESTION_TIME!}
              sx={{
                color: showTimer ? "red" : "transparent",
                fontSize: 12,
                fontWeight: "bold",
                transition: "1s ease",
              }}
              returnUpdatedValue={(updated) => setShowTimer(updated < 60)}
              suffixText="seconds left"
              onStopTimer={() => {
                setRenderTimer(false);
                onEndTime?.();
              }}
            />
          </div>
        )}
      </Row>
    </Row>
  );
};

export default UserChat;
