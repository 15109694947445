import Checkbox from "@mui/material/Checkbox";
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses, TooltipProps} from "@mui/material/Tooltip";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import AudioLevelTracker from "components/AudioWave";
import InterviewActionButton from "components/Button/InterviewActionButton";
import InterviewHeader from "components/InterviewHeader";
import PermissionsErrorModal from "components/PermissionsErrorModal";
import {Webcam} from "components/Webcam";
import {AppColors} from "globals/appcolors";
import {microCopies} from "globals/constants/microcopies";
import {RouteLinks} from "globals/constants/routeLinks";
import {DialogType} from "globals/types/globalTypes";
import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions
} from "mic-check";
import {memo, useEffect, useRef, useState} from "react";
import {RiArrowDropDownLine} from "react-icons/ri";
import {useNavigate, useSearchParams} from "react-router-dom";
import styles from "./InterviewPreview.module.css";

const label = {inputProps: {"aria-label": "Checkbox demo"}};

const StyledTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
  opacity: 0.8,
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: AppColors.PRIMARYCOLOR,
    color: AppColors.WHITE,
    border: `1px solid ${AppColors.PRIMARYCOLOR}`,
    padding: 10,
    fontWeight: "bold"
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: AppColors.PRIMARYCOLOR,
    border: "none",
    borderColor: "transparent"
  }
}));

const InterviewPing = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const assessmentId = searchParams.get("assessment");
  const token = searchParams.get("token");

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const {setPermissions, permissions, getMicrocopy} =
    useAppCommonDataProvider();

  const [isQualityGood, setIsQualityGood] = useState<boolean>(false);
  const [defaultCamera, setDefaultCamera] = useState<string | null>(null);
  const [defaultMic, setDefaultMic] = useState<string | null>(null);
  const [isPermissionsModalOpen, setIsPermissionsModalOpen] =
    useState<boolean>(false);
  const [permissionError, setPermissionError] = useState<DialogType | null>(
    null
  );
  const [startAudioTracker, setStartAudioTracker] = useState(false);
  const showDialogRef = useRef<DialogType | null>(permissionError);
  showDialogRef.current = permissionError;
  const [termsConditions, setTermsConditions] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);
  const [recorder, setRecorder] = useState<MediaRecorder | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const isNextDisabled = !(
    termsConditions &&
    isQualityGood &&
    recordedChunks?.length > 0
  );

  function checkForExplanationDialog() {
    if (
      (!permissions?.camera || !permissions.microphone) &&
      showDialogRef.current === null
    )
      setPermissionError(DialogType.explanation);
  }

  async function checkPermissions() {
    if (!("navigator" in window)) {
      setIsPermissionsModalOpen(false);
    } else {
      requestMediaPermissions()
        .then(() => {
          setIsPermissionsModalOpen(false);
          setPermissions?.({
            ...permissions,
            camera: true,
            microphone: true
          });
        })
        .catch((e: MediaPermissionsError) => {
          setIsPermissionsModalOpen(true);
          const {type} = e;
          if (type === MediaPermissionsErrorType.SystemPermissionDenied)
            setPermissionError(DialogType.systemDenied);
          else if (type === MediaPermissionsErrorType.UserPermissionDenied)
            setPermissionError(DialogType.userDenied);
          else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource)
            setPermissionError(DialogType.trackError);
          else setPermissionError(DialogType.explanation);
        });

      setTimeout(() => checkForExplanationDialog(), 500);
    }
  }

  useEffect(() => {
    const getMediaDevices = async () => {
      let audioInputDevices = [];
      let videoInputDevices = [];

      try {
        await navigator.mediaDevices.getUserMedia({video: true});
        const devices = await navigator.mediaDevices.enumerateDevices();
        videoInputDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );

        if (videoInputDevices.length > 0) {
          setDefaultCamera(videoInputDevices[0].label || "Default Camera");
        } else {
          setDefaultCamera("Not found");
        }
      } catch (error) {
        console.warn("Error accessing video devices:", error);
        setDefaultCamera("Not found");
      }

      try {
        await navigator.mediaDevices.getUserMedia({audio: true});
        const devices = await navigator.mediaDevices.enumerateDevices();
        audioInputDevices = devices.filter(
          (device) => device.kind === "audioinput"
        );

        if (audioInputDevices.length > 0) {
          setDefaultMic(audioInputDevices[0].label || "Default Microphone");
        } else {
          setDefaultMic("Not found");
        }
      } catch (error) {
        console.warn("Error accessing audio devices:", error);
        setDefaultMic("Not found");
      }
    };

    getMediaDevices();
    checkPermissions();
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      const audio = audioRef.current;
      const handleAudioEnd = () => {
        setIsPlaying(false);
      };

      audio.addEventListener("ended", handleAudioEnd);
      return () => {
        audio.removeEventListener("ended", handleAudioEnd);
      };
    }
  }, [audioRef.current]);

  const playPauseAudio = (action: string) => {
    stopRecording();
    if (!audioRef.current && recordedChunks.length > 0) {
      const mimeType = MediaRecorder.isTypeSupported("audio/mp4")
        ? "audio/mp4"
        : "audio/webm";
      const audioBlob = new Blob(recordedChunks, {type: mimeType});
      const audioUrl = URL.createObjectURL(audioBlob);
      audioRef.current = new Audio(audioUrl);
    }
    if (audioRef.current) {
      if (action === "play" && !isPlaying) {
        audioRef.current
          .play()
          .catch((err) => console.error("Error playing audio:", err));
        setIsPlaying(true);
      }
      if (action === "pause") {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const startRecording = async () => {
    if (defaultMic === "Not found") checkPermissions();
    else setStartAudioTracker(true);
  };

  const stopRecording = () => {
    setStartAudioTracker(false);
    if (recorder && recorder.state !== "inactive") {
      recorder.stop();
      recorder.stream.getTracks().forEach((track) => track.stop());
      const mimeType = MediaRecorder.isTypeSupported("audio/mp4")
        ? "audio/mp4"
        : "audio/webm";
      const audioBlob = new Blob(recordedChunks, {type: mimeType});
      const audioUrl = URL.createObjectURL(audioBlob);
      audioRef.current = new Audio(audioUrl); // Store the audio object for later use
      setIsPlaying(true);
      playPauseAudio("play");
    }
  };

  return (
    <div className={styles.main_wrapper}>
      <InterviewHeader
        title={getMicrocopy(microCopies.INTERVIEW_MIC_CAMERA_CHECK)}
      />

      <div className={styles.check_layout}>
        <div
          className={`${styles.system_check_wrapper} ${
            isQualityGood ? "opacity-60" : ""
          }`}
        >
          <div className={styles.camera_wrapper}>
            <Webcam />
          </div>

          <div className={`${styles.frame_div} !py-3 lg:!py-4 `}>
            <div className="flex justify-between items-center">
              <p className={styles.quality_head}>Video Quality</p>
              <div className={styles.quality_desc}>
                {defaultCamera || "Fetching camera..."}
                <RiArrowDropDownLine size={20} />
              </div>
            </div>

            <p className={styles.quality_subhead}>
              Are you able to view your video clearly?
            </p>
            <p className={styles.read_line}>
              {getMicrocopy(microCopies.CHECK_CONNECTION_VIDEO_QUALITY)}
            </p>

            <div className={styles.btn_wrapper}>
              <button
                disabled={defaultCamera !== "Not found"}
                className={`${styles.white_btn} ${
                  defaultCamera !== "Not found" &&
                  "bg-gray-400 opacity-40 cursor-not-allowed"
                }`}
                onClick={checkPermissions}
              >
                No
              </button>
              <button
                className={styles.blue_btn}
                onClick={() => setIsQualityGood(true)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>

        <div className={styles.system_check_wrapper}>
          <div className={styles.speaker_wrapper}>
            <p className={styles.label}>
              Speak to check your microphone, you will hear your voice
              (Mandatory)
            </p>

            <div className="mt-[20px]">
              <AudioLevelTracker
                startAudioTracker={startAudioTracker}
                setRecordedChunks={setRecordedChunks}
                setRecorder={setRecorder}
              />
            </div>
          </div>

          <div
            className={`${styles.frame_div} ${
              !isQualityGood && "opacity-60"
            } !py-3 lg:!py-4`}
          >
            <div className="flex justify-between items-center">
              <p className={styles.quality_head}>Audio Quality</p>
              <div className={styles.quality_desc}>
                {defaultMic || "Fetching microphone..."}
                <RiArrowDropDownLine size={20} />
              </div>
            </div>

            <p className={styles.quality_subhead}>Read the below text</p>
            <p className={styles.read_line}>
              {getMicrocopy(microCopies.MICROPHONE_TEST_ADJUST_VOLUME)}
            </p>

            <div className={`${styles.btn_wrapper} items-center`}>
              <button
                disabled={!isQualityGood}
                className={styles.blue_btn}
                onClick={startAudioTracker ? stopRecording : startRecording}
              >
                {startAudioTracker ? "Stop" : "Speak"}
              </button>
              {recordedChunks?.length > 0 &&
                (isPlaying ? (
                  <button
                    onClick={() => {
                      playPauseAudio("pause");
                    }}
                    className={styles.play_pause_btn}
                  >
                    Pause
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      playPauseAudio("play");
                    }}
                    className={styles.play_pause_btn}
                  >
                    Play
                  </button>
                ))}
            </div>
          </div>
        </div>

        <div className={styles.lower_div}>
          <div className="flex lg:gap-[6px] items-center">
            <Checkbox
              {...label}
              checked={termsConditions}
              onClick={() => setTermsConditions(!termsConditions)}
              sx={{
                color: "#717E95",
                "&.Mui-checked": {
                  color: "#2B51EB"
                }
              }}
            />
            <p className={styles.terms_conditions_text}>
              I agree to{" "}
              <span className="text-[#2B51EB] font-bold cursor-pointer">
                terms & conditions
              </span>{" "}
              of this AI interview process
            </p>
          </div>
          <div className={`!mt-0 ${styles.btn_wrapper}`}>
            <InterviewActionButton
              isDarkMode={false}
              label="Back"
              handleClick={() => {
                navigate(
                  `/${RouteLinks?.Interview_Preview}?assessment=${assessmentId}&token=${token}`
                );
              }}
            />
            <StyledTooltip
              title={
                isNextDisabled
                  ? "Please complete all checks and agree to the terms and conditions"
                  : ""
              }
              arrow
            >
              <button
                onClick={() => {
                  if (isNextDisabled) {
                    return;
                  }
                  playPauseAudio("pause");
                  navigate(
                    `/${RouteLinks.Interview_Round_2}?assessment=${assessmentId}&token=${token}`
                  );
                }}
                className={`${
                  isNextDisabled ? "opacity-50 cursor-not-allowed" : ""
                } bg-[#000] text-[#fff] px-4 lg:px-6 py-2 lg:py-3 leading-[21.6px] text-center text-16 lg:text-18 font-semibold rounded-[6px] h-10 lg:h-[47px] min-w-[60px] lg:min-w-[87px]`}
              >
                {"Start Interview"}
              </button>
            </StyledTooltip>
          </div>
        </div>
      </div>

      {isPermissionsModalOpen && (
        <PermissionsErrorModal
          isOpen={isPermissionsModalOpen}
          errorType={permissionError}
        />
      )}
    </div>
  );
};

export default memo(InterviewPing);
