import {RouteLinks} from "globals/constants/routeLinks";
import {RouteNames} from "globals/constants/routeNames";
import CandidateIcon from "globals/svgs/icons/candidate_user.svg";
import CreateQuestionIcon from "globals/svgs/icons/create-question.svg";
import DashboardIcon from "globals/svgs/icons/dashboardIcon.svg";
import ProjectIcon from "globals/svgs/icons/projectsIcon.svg";
import {Routes} from "globals/types/globalTypes";

export const routes: Array<Routes> = [
  {
    name: RouteNames.Dashboard,
    link: `/${RouteLinks.Dashboard}`,
    logo: DashboardIcon,
  },
  {
    name: RouteNames.Assessments,
    link: `/${RouteLinks.Assessment_Page}`,
    logo: ProjectIcon,
  },
  // {
  //   name: RouteNames.Assessment_Test,
  //   link: `/${RouteLinks.Assessment_Test}`,
  //   logo: AssessmentTestIcon,
  // },
  {
    name: RouteNames.Create_Question,
    link: `/${RouteLinks.AllQuestion}`,
    logo: CreateQuestionIcon,
  },
  {
    name: RouteNames.Candidate,
    link: `/${RouteLinks.Candidate}`,
    logo: CandidateIcon,
  },
];

export const specialRoutes = [
  "/login",
  "/",
  "/register",
  "/resetpassword",
  "/passwordchange",
  "/interview",
  "/reset-password",
  "/college-landing-page",
  `/${RouteLinks.Candidate_Login}`,
  `/${RouteLinks.Interview__Questions}`,
  `/${RouteLinks.Password_Change}`,
  `/${RouteLinks.SignUp}`,
  `/${RouteLinks.Pricing}`,
  `/${RouteLinks.ReportCard}`,
  `/${RouteLinks.Create_Oneup_Assessment}`,
  "/create",
  "/sh",
];
