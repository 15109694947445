import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import {APIEndPoints} from "globals/constants/endPoints";
import {staticHeaders} from "globals/helpers";
import {CreateAssessmentResponse} from "globals/types/APIResponses";
import {CreateAsseessmentFields} from "globals/types/globalTypes";
import {toast} from "react-toastify";

export const useCreateAssessment = (
  bulk?: boolean,
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
): UseMutationResult<
  CreateAssessmentResponse | void,
  unknown,
  FormData | CreateAsseessmentFields
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["createAssessment"],
    mutationFn: async (data: FormData | CreateAsseessmentFields) => {
      return axios
        .post<
          any,
          CreateAssessmentResponse,
          FormData | CreateAsseessmentFields
        >(
          bulk === undefined || bulk === false
            ? APIEndPoints.ASSESMENT_NODE
            : APIEndPoints.CREATE_BULK_ASSESSMENTS,
          data,
          {headers: staticHeaders()}
        )
        .then((res) => {
          if (res.data.code === 201) {
            queryClient.invalidateQueries(["createAssessment"]);
            return res;
          }
        })
        .catch((err) => {
          const {response} = err || {};
          const {status, data} = response || {};

          if (status === 500) {
            toast.error("Something went wrong on our end!", {
              position: "bottom-left",
            });
            setLoading?.(false);
            return;
          } else if (status === 422) {
            toast.error(data?.message, {
              position: "bottom-left",
            });
            setLoading?.(false);
            return;
          }
          const errors = Object.values(err.response.data.errors);
          toast.error(errors[0] as string, {
            position: "bottom-left",
          });
          setLoading?.(false);
        });
    },
  });
};
