import {Typography} from "@mui/material";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import "App.css";
import {AppCommonDataProvider} from "components/AppCommonDataProvider";
import LoginCard from "components/Containers/Cards/LoginCard/LoginCard";
import MainContainer from "components/Containers/MainContainer/MainContainer";
import AppNav from "components/NavBars/AppNav/AppNav";
import {Row} from "components/Row";
import {AppColors} from "globals/appcolors";
import {
  AllInterviewsLinks,
  AssesmentLinks,
  AssesmentTemplateLinks,
  CandidateLinks,
  QuestionLinks,
  RouteLinks
} from "globals/constants/routeLinks";
import strings from "globals/constants/strings.json";
import {initMixpanel} from "globals/helpers";
import React, {useEffect, useState} from "react";
import CacheBuster from "react-cache-buster";
import GoogleAnalytics from "react-ga";
import Geocode from "react-geocode";
import "react-responsive-modal/styles.css";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {BehaviorQuestions} from "routes/Interview/BehaviorQuestions";
import InterviewFlash from "routes/Interview/InterviewFlash";
import InterviewInstructions from "routes/Interview/InterviewInstructions";
import InterviewIntroductionPreview from "routes/Interview/InterviewIntroductionPreview";
import InterviewPing from "routes/Interview/InterviewPing";
import InterviewPreview from "routes/Interview/InterviewPreview";
import InterviewQuestions from "routes/Interview/InterviewQuestions";
import {SecondRoundInterview} from "routes/Interview/SecondRoundInterview";
import ThankYouScreen from "routes/Interview/ThankYouScreen";
import LandingPageV2 from "routes/LandingPageV2";
import Profile from "routes/Profile/Profile";
import RoleManagement from "routes/Profile/RoleManagement";
import PasswordChange from "routes/ResetPassword/PasswordChange";
import ResetPassword from "routes/ResetPassword/ResetPassword";
import AssessmentPage from "routes/assessment/AssessmentPage/AssessmentPage";
import ExpiredAssessments from "routes/assessment/AssessmentPage/ExpiredAssessments";
import ReuseAssessment from "routes/assessment/AssessmentPage/ReuseAssessment";
import AssessmentQuestions from "routes/assessment/AssessmentQuestions/AssessmentQuestions";
import AssessmentTest from "routes/assessment/AssessmentTest";
import AssessmentDetails from "routes/assessment/AssessmentTest/AssessmentDetails";
import CreateAssessmentTest from "routes/assessment/AssessmentTest/CreateAssessmentTest";
import CandidateDetail from "routes/candidate/CandidateDetail/CandidateDetail";
import ReportCard from "routes/candidate/CandidateDetail/ReportCard";
import SeeAnswers from "routes/candidate/CandidateDetail/SeeAnswers";
import Candidate from "routes/candidate/CandidateHome/Candidate";
import Dashboard from "routes/dashboard/DashboardHome/Dashboard";
import AllInterviews from "routes/dashboard/DashboardHome/DashboardTable/AllInterviewsComponent/AllInterviews";
import ShortenedInterview from "routes/interview-shorthand";
import {CandidateLogin} from "routes/login/CandidateLogin/CandidateLogin";
import NotFound from "routes/notFound/NotFound";
import OneUpAssessment from "routes/one-up-assessment";
import Question from "routes/question/AllQuestions/AllQuestions";
import CreateQuestion from "routes/question/CreateQuestion/CreateQuestion";
import CsvQuestions from "routes/question/CsvQuestions/CsvQuestions";
import Screenshots from "routes/screenshots";
import packageData from "../package.json";

const App: React.FC = () => {
  const materialTheme = createTheme({
    typography: {
      fontFamily: ["Mulish"].join(",")
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            background: AppColors.PRIMARYCOLOR,
            color: AppColors.WHITE,
            textTransform: "inherit",
            "&:hover": {
              background: AppColors.PRIMARYCOLOR
            },
            "&:disabled": {
              background: AppColors.GREY,
              color: "white"
            }
          }
        }
      }
    }
  });

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const handleResize = (e: UIEvent) => {
    //@ts-ignore
    setWindowWidth(e.target.innerWidth);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    initMixpanel();
    GoogleAnalytics.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY!);
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY!);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const myErrorHandler = (error: Error, info: {componentStack: string}) => {
    console.log(
      `ErrorBoundary's error: ${error} | ComponentStack: ${JSON.stringify(
        info
      )}`
    );
  };

  const queryClient = new QueryClient();

  const MobileView: React.FC = () => {
    return (
      <Row classNames="bg-white-900 h-screen justify-center">
        <section
          className="shadow-all-around p-6 rounded-xl mobile__viewCard"
          style={{maxWidth: "80vw", minWidth: "80vw"}}
        >
          <Typography
            variant="h3"
            sx={{fontWeight: "500", color: AppColors.PRIMARYCOLOR}}
          >
            {strings.uh_oh}
          </Typography>
          <Typography sx={{marginTop: 2, textAlign: "justify"}}>
            {strings.mobile_device}
          </Typography>
        </section>
      </Row>
    );
  };

  if (windowWidth >= 768)
    return (
      <CacheBuster
        currentVersion={packageData.version}
        isEnabled={process.env.NODE_ENV === "production"}
        isVerboseMode={false}
        loadingComponent={<></>}
      >
        <AppCommonDataProvider>
          <QueryClientProvider client={queryClient}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <ThemeProvider theme={materialTheme}>
                <div className="App">
                  <Router>
                    <div style={{display: "flex", flexDirection: "column"}}>
                      <AppNav />
                      <MainContainer>
                        <Routes>
                          <Route path="/" element={<LandingPageV2 />} />
                          <Route
                            path={RouteLinks.Dashboard}
                            element={<Dashboard />}
                          />
                          <Route
                            path={RouteLinks.Login}
                            element={<LoginCard />}
                          />
                          <Route
                            path={RouteLinks.Reset_Password}
                            element={<ResetPassword />}
                          />
                          <Route
                            path={RouteLinks.Password_Change}
                            element={<PasswordChange />}
                          />
                          <Route
                            path={RouteLinks.Assessment_Page}
                            element={<AssessmentPage />}
                          />
                          <Route
                            path={RouteLinks.Candidate}
                            element={<Candidate />}
                          />
                          <Route
                            path={RouteLinks.AllQuestion}
                            element={<Question />}
                          />

                          <Route
                            path={RouteLinks.Interview_Preview}
                            element={<InterviewPreview />}
                          />
                          <Route
                            path={RouteLinks.Interview_Ping}
                            element={<InterviewPing />}
                          />
                          <Route
                            path={RouteLinks.Interview_Finish}
                            element={<ThankYouScreen />}
                          />
                          <Route
                            path={`${RouteLinks.Interview}/:id/:assessmentId`}
                            element={<InterviewInstructions />}
                          />
                          <Route
                            path={`${RouteLinks.Interview}/:id/:assessmentId/behavior`}
                            element={<BehaviorQuestions />}
                          />
                          <Route
                            path={`${RouteLinks.Interview}/:id/:assessmentId/introduction`}
                            element={<InterviewIntroductionPreview />}
                          />
                          <Route
                            path={`${RouteLinks.Interview}/:id/:assessmentId/start`}
                            element={<InterviewFlash />}
                          />
                          <Route
                            path={`${RouteLinks.Interview}/:id/:assessmentId/questions`}
                            element={<InterviewQuestions />}
                          />
                          <Route
                            path={`${RouteLinks.Interview_Round_2}`}
                            element={<SecondRoundInterview />}
                          />
                          <Route
                            path={RouteLinks.Assessment_Test}
                            element={<AssessmentTest />}
                          />
                          <Route
                            path={`${RouteLinks.Assessment_Test}/:id`}
                            element={<AssessmentDetails />}
                          />
                          <Route
                            path={`${AssesmentTemplateLinks.Reuse_Assessment}/:id`}
                            element={<ReuseAssessment />}
                          />
                          <Route
                            path={AssesmentTemplateLinks.Create_Assessment_Test}
                            element={<CreateAssessmentTest />}
                          />
                          <Route
                            path={AssesmentLinks.Expired_Assessment}
                            element={<ExpiredAssessments />}
                          />
                          {/* <Route
                        path={AssesmentLinks.Create_Assessment}
                        element={<CreateAssessment />}
                      /> */}
                          <Route
                            path={CandidateLinks.Candidate_Detail}
                            element={<CandidateDetail />}
                          />
                          <Route
                            path={`${CandidateLinks.See_Ansers}/:id`}
                            element={<SeeAnswers />}
                          />
                          <Route
                            path={QuestionLinks.Create_Question}
                            element={<CreateQuestion />}
                          />
                          <Route
                            path={QuestionLinks.Csv_Questions}
                            element={<CsvQuestions />}
                          />
                          <Route path={"*"} element={<NotFound />} />
                          <Route
                            path={`${RouteLinks.Profile}`}
                            element={<Profile />}
                          />
                          <Route
                            path={`${RouteLinks.RoleManagement}`}
                            element={<RoleManagement />}
                          />
                          <Route
                            path={`/${AssesmentLinks.View_Assessment_Questions}`}
                            element={<AssessmentQuestions />}
                          />
                          <Route
                            path={`${RouteLinks.Candidate_Login}`}
                            element={<CandidateLogin />}
                          />
                          <Route
                            path={RouteLinks.ReportCard}
                            element={<ReportCard />}
                          />
                          <Route
                            path={`${RouteLinks.Screenshots}/:assessmentId`}
                            element={<Screenshots />}
                          />

                          <Route
                            path={AllInterviewsLinks.AllInterviewpage}
                            element={<AllInterviews />}
                          />
                          <Route
                            path={`${RouteLinks.Create_Oneup_Assessment}/:skill`}
                            element={<OneUpAssessment />}
                          />
                          <Route
                            path={`sh/:id`}
                            element={<ShortenedInterview />}
                          />
                        </Routes>
                      </MainContainer>
                      <ToastContainer />
                    </div>
                  </Router>
                </div>
              </ThemeProvider>
            </LocalizationProvider>
          </QueryClientProvider>
        </AppCommonDataProvider>
      </CacheBuster>
    );
  else return <MobileView />;
};

export default App;
