import {Checkbox} from "@mui/material";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import InterviewActionButton from "components/Button/InterviewActionButton";
import {useChat} from "components/ChatProvider";
import {Row} from "components/Row";
import SystemChat from "components/SystemChat";
import {CountDownTimer} from "components/Timer";
import UserChat from "components/UserChat";
import {Webcam} from "components/Webcam";
import {newInstance} from "globals/axiosConstants";
import {APIEndPoints} from "globals/constants/endPoints";
import {microCopies} from "globals/constants/microcopies";
import {s3Config} from "globals/constants/s3Config";
import {createSecondRoundQuestion, postMixPanelEvent} from "globals/helpers";
import {
  RuntimeQuestion,
  SecondRoundQuestion,
  SecondRoundQuestionType
} from "globals/types/globalTypes";
import {Images} from "Images";
import axiosPostModule from "network/Post/AxiosPostModule";
import React, {useEffect, useState} from "react";
import S3Client from "react-aws-s3-typescript";
import {FullScreen, FullScreenHandle} from "react-full-screen";
import {useSearchParams} from "react-router-dom";
import {io} from "socket.io-client";
import {v4 as uuid} from "uuid";
import CodingRound from "./CodingRound";
import interviewCommonStyles from "./Interview.module.scss";
import styles from "./SecondRoundInterview.module.scss";

interface IProps {
  videoConstraints: MediaTrackConstraints;
  fullScreenHandle: FullScreenHandle;
  showTimer: boolean | undefined;
  questions: SecondRoundQuestion[];
  isTestFinishing: boolean | undefined;
  handleFullScreenChange: (state: boolean, handle: any) => void;
  onPressEnd: () => void;
  stopRecording: () => void;
}

const socket = io(process.env.REACT_APP_SOCKET_URL);

let recorder: MediaRecorder | null = null;

const clearRecorders = () => {
  if (recorder && recorder.state !== "inactive") {
    recorder.stop();
    recorder.stream.getTracks().forEach((track) => track.stop());
    recorder = null;
  }
};

const initRecorder = async (): Promise<MediaRecorder> => {
  const stream = await navigator.mediaDevices.getUserMedia({
    audio: {echoCancellation: true, noiseSuppression: true}
  });
  const recorder = new MediaRecorder(stream, {mimeType: "audio/webm"});
  return recorder;
};

const label = {inputProps: {"aria-label": "Checkbox demo"}};

export const SecondRoundInterviewFlow: React.FC<IProps> = ({
  videoConstraints,
  questions,
  fullScreenHandle,
  showTimer,
  isTestFinishing,
  handleFullScreenChange,
  onPressEnd,
  stopRecording
}) => {
  const [queryParams] = useSearchParams();
  const assessmentId = queryParams.get("assessment");
  const isDummy = queryParams.get("isDummy");

  const {answer, setAnswer, audio, setAudio, chats, setChats} = useChat();
  const {interview_details, getMicrocopy, siteConstants} =
    useAppCommonDataProvider();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [processingResponse, setProcessingResponse] = useState<boolean>(false);
  const [roomId, setRoomId] = useState<string>("");
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
  const [allQuestions, setAllQuestions] = useState<SecondRoundQuestion[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [currentBehaviourQuestionIndex, setCurrentBehaviourQuestionIndex] =
    useState<number>(1);
  const [showPhaseOneCompletedSection, setShowPhaseOneCompletedSection] =
    useState<boolean>(false);
  const [currentCodingQuestionIndex, setCurrentCodingQuestionIndex] =
    useState<number>(1);
  const [currentQuestionType, setCurrentQuestionType] =
    useState<SecondRoundQuestionType>("Technical_analysis");
  const [technicalRoundRemainingTime, setTechnicalRoundRemainingTime] =
    useState<number>(900);
  const [behaviouralRoundRemainingTime, setBehaviouralRoundRemainingTime] =
    useState<number>(420);
  const [isInFullScreen, setIsInFullScreen] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const currentQuestion = allQuestions[currentQuestionIndex];
  const chatsLength = chats.length;
  const createCrossQuestion = currentQuestionIndex % 2 === 0;

  const isCodingQuestion = currentQuestion
    ? currentQuestion.overall_type === "coding_analysis"
    : false;
  const isBehaviourQuestion = currentQuestion
    ? currentQuestion.overall_type === "behavioral_analysis"
    : false;
  const isTechnicalQuestion = currentQuestion
    ? currentQuestion.overall_type === "Technical_analysis"
    : false;

  const s3client = new S3Client(s3Config);

  const numberOfTechnicalQuestions = Number(
    siteConstants.find(
      (constant) => constant.title === "number_of_technical_questions"
    )!.value
  );

  const {
    main_question_id: documentId,
    candidate: {
      user: {first_name: candidateFirstName, last_name: candidateLastName}
    }
  } = interview_details?.data.assessment!;

  const startAudioRecording = async () => {
    const newId = uuid();

    recorder = await initRecorder();

    socket.emit("start_stream", {
      room_id: newId
    });

    recorder.start(600);
    recorder.ondataavailable = ({data}) => {
      setAudioChunks((prev) => [...prev, data]);
      socket.emit("audio_chunk", {
        audio_chunk: data,
        room_id: newId
      });
    };

    setRoomId(newId);
  };

  const stopCurrentAudio = () => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }
  };

  const saveTranscribedResponse = async () => {
    const {ai_question_id} = currentQuestion;

    socket.emit("delete_question", {
      document_id: documentId,
      question_id: ai_question_id,
      room_id: roomId
    });

    const {
      data: {
        audio_file_path_mp3,
        cross_question,
        cross_question_id,
        real_time_question
      }
    } = await newInstance.post("/update_answer", {
      document_id: documentId,
      question_id: ai_question_id,
      answer: answer.trim() === "" ? "-" : answer,
      cross_question: createCrossQuestion
    });

    if (createCrossQuestion) {
      const newQuestion = createSecondRoundQuestion({
        ai_question_id: cross_question_id,
        assessment_id: +assessmentId!,
        audio_file_path_mp3,
        isFollowUpQuestion: true,
        overall_type: "Technical_analysis",
        questionText: cross_question
      });

      // Insert cross question between current and next question
      const newQuestions = [...allQuestions];
      newQuestions.splice(currentQuestionIndex + 1, 0, newQuestion);
      setAllQuestions(newQuestions);
      setChats?.([
        ...chats,
        {
          chat: cross_question,
          isUserChat: false
        }
      ]);
    } else {
      setChats?.([
        ...chats,
        {
          chat: allQuestions[currentQuestionIndex + 1].question,
          isUserChat: false
        }
      ]);
    }

    // Check if a new question is generated at runtime and add it to the current
    // question list. Also ensure it is not the last question being saved.
    if (
      real_time_question &&
      !(currentQuestionIndex === numberOfTechnicalQuestions * 2 - 2)
    ) {
      const {audio_file_path_mp3, questionId, questionText} =
        real_time_question;

      // Save new question to the node backend to maintain parity amongst the
      // questions list between both node and python databases
      const {data: newNodeQuestion} = await axiosPostModule(
        APIEndPoints.ADD_QUESTION_TO_INTERVIEW,
        {
          assessment_id: assessmentId,
          question_id: questionId,
          question_text: questionText,
          __typename: "",
          options: JSON.stringify([]),
          overall_type: "Technical_analysis",
          question_answer: "",
          audio_url: audio_file_path_mp3
        }
      );

      const {ai_question_id, id} = newNodeQuestion;

      const newQuestion = createSecondRoundQuestion({
        ai_question_id,
        assessment_id: +assessmentId!,
        audio_file_path_mp3,
        id,
        isFollowUpQuestion: false,
        overall_type: "Technical_analysis",
        questionText
      });

      setAllQuestions((prev) => [...prev, newQuestion]);
    }

    emitStopStreamEvent();
    clearValuesAndMoveToNextQuestion();
  };

  const stopAudioRecording = async (isTemporary?: boolean) => {
    if (isTemporary) {
      stopCurrentAudio();
      clearRecorders();
      return;
    }

    clearRecorders();
    saveTranscribedResponse();
  };

  const emitStopStreamEvent = () => {
    socket.emit("stop_stream", {
      room_id: roomId
    });
  };

  const clearValuesAndMoveToNextQuestion = () => {
    setAudioChunks([]);
    setCurrentQuestionIndex((prev) => prev + 1);
    setAnswer?.("");
    setRoomId(uuid());

    setTimeout(() => {
      setProcessingResponse(false);
    }, 10);
    setTimeout(() => {
      setDisabled(false);
    }, 1000);
  };

  const handleQuestionChange = async (code?: string) => {
    try {
      if (audio && !audio.paused) {
        return;
      }

      if (isTechnicalQuestion) {
        setDisabled(true);
        setProcessingResponse(true);
      }

      // Stop the audio if it is currently being played.
      // TODO: could potentially be removed since the next button has already been disabled
      // if the audio is being played.
      stopCurrentAudio();

      // Check for if we are trying to test things on the landing page
      if (isDummy) {
        return setCurrentQuestionIndex((prev) => prev + 1);
      }

      if (isBehaviourQuestion) {
        const nextQuestion = allQuestions[currentQuestionIndex + 1];
        if (!nextQuestion) {
          return stopRecording();
        } else {
          return handleBehaviorQuestionResponse();
        }
      }

      if (isCodingQuestion) {
        return handleCodingQuestionResponse(code);
      }

      // Check if it is the last technical question's follow up question
      if (currentQuestionIndex === numberOfTechnicalQuestions * 2 - 1) {
        emitStopStreamEvent();
        await createBehaviourQuestions();
      }

      // Proceed normally for saving response of the techincal questions
      stopAudioRecording();
    } catch (e) {
      console.error(
        "we ran into an error when trying to go to the next question"
      );
      postMixPanelEvent("Failed to switch to next question", {
        candidateName: `${candidateFirstName}-${candidateLastName}`,
        error: e
      });
      // @ts-ignore
      throw new Error(e);
    }
  };

  const handleFullScreenEnter = () => {
    if (isInFullScreen) return;
    setIsInFullScreen(true);
  };

  const onCaptureScreenshot = (url: string) => {
    fetch(url)
      .then((res) => res.blob())
      .then(async (blob) => {
        const id = uuid();
        const firstName =
          interview_details?.data.assessment.candidate.user.first_name.replace(
            /[^a-zA-Z0-9]/g,
            "-"
          );
        const lastName =
          interview_details?.data.assessment.candidate.user.last_name.replace(
            /[^a-zA-Z0-9]/g,
            "-"
          );
        const fileName = `${firstName}-${lastName}-${id}-interview-screenshot.png`;
        const file = new File([blob], fileName, {type: "image/png"});

        const s3Response = await s3client.uploadFile(file);

        axiosPostModule(`${APIEndPoints.SEND_SCREENSHOT(assessmentId!)}`, {
          url: s3Response.location
        });
      });
  };

  const createBehaviourQuestions = async () => {
    stopAudioRecording();
    setProcessingResponse(true);
    const data = new FormData();
    data.append("documentId", documentId!);
    const {
      data: {new_questions}
    } = await newInstance.post("/behavioral-compatibility", data);

    const resolvedQuestions: SecondRoundQuestion[] = [];
    new_questions.forEach(
      ({questionId, questionText, question_options}: RuntimeQuestion) => {
        const newQuestion = createSecondRoundQuestion({
          ai_question_id: questionId,
          assessment_id: +assessmentId!,
          isFollowUpQuestion: false,
          overall_type: "behavioral_analysis",
          questionText: questionText,
          options: question_options,
          isBehaviourQuestion: true
        });

        resolvedQuestions.push(newQuestion);
      }
    );

    setAllQuestions((prev) => [...prev, ...resolvedQuestions]);
    clearValuesAndMoveToNextQuestion();
  };

  const handleCodingQuestionResponse = (code?: string) => {
    socket.disconnect();

    const data = new FormData();
    data.append("answer", code ?? " ");
    data.append(
      "documentId",
      interview_details?.data.assessment.main_question_id!
    );
    data.append("questionId", currentQuestion.ai_question_id);

    newInstance.post("/coding-answers", data);

    // Check if next question is final question or not.
    // If it is, finish the assessment
    if (currentQuestionIndex + 1 === allQuestions.length) {
      stopRecording();
      return;
    }

    if (currentQuestionIndex + 1 !== allQuestions.length) {
      setCurrentQuestionIndex((prev) => prev + 1);
      setCurrentCodingQuestionIndex((prev) => prev + 1);
    }
  };

  const fetchCodingQuestions = () => {
    const data = new FormData();
    data.append("documentId", documentId!);
    const codingQuestions: SecondRoundQuestion[] = [];

    newInstance
      .post("/coding-compatibility", data)
      .then(({data: {new_questions}}) => {
        new_questions.forEach(({questionId, questionText}: RuntimeQuestion) => {
          const codingQuestion = createSecondRoundQuestion({
            ai_question_id: questionId,
            assessment_id: +assessmentId!,
            isFollowUpQuestion: false,
            overall_type: "coding_analysis",
            questionText,
            isTechnicalQuestion: true
          });
          codingQuestions.push(codingQuestion);
        });

        setAllQuestions((prev) => [...prev, ...codingQuestions]);
      });
  };

  const handleBehaviorQuestionResponse = () => {
    // Fetch coding questions after submitting first response.
    if (currentBehaviourQuestionIndex === 1) {
      fetchCodingQuestions();
    }
    socket.disconnect();

    const data = new FormData();
    data.append("answer", JSON.stringify(selectedOptions));
    data.append("documentId", documentId!);
    data.append("questionId", currentQuestion.ai_question_id);

    newInstance.post("/behav-answers ", data).finally(() => {
      setCurrentBehaviourQuestionIndex((prev) => prev + 1);
      setCurrentQuestionIndex((prev) => prev + 1);
      setSelectedOptions([]);
    });

    // Check if the current question is behavioural and if next question is coding
    const nextQuestion = allQuestions[currentQuestionIndex + 1];
    if (nextQuestion.overall_type === "coding_analysis") {
      setShowPhaseOneCompletedSection(true);
    }
  };

  const handleTechnicalRoundTimerChange = (remaining: number) => {
    // Check if time remaining for the technical round is 10 seconds and no behaviour questions exist for the current interview.
    // Create behaviour questions if they do exist.
    if (
      remaining === 10 &&
      allQuestions.filter(
        (question) => question.overall_type === "behavioral_analysis"
      ).length === 0
    ) {
      createBehaviourQuestions();
    }
    setTechnicalRoundRemainingTime(remaining);
  };

  useEffect(() => {
    if (!currentQuestion) {
      return;
    }

    if (currentQuestion.overall_type !== currentQuestionType) {
      setCurrentQuestionType(currentQuestion.overall_type);
    }

    if (!isInFullScreen) return;

    if (!isCodingQuestion && !isBehaviourQuestion) {
      const newAudio = new Audio(currentQuestion.audio_url);
      newAudio.playbackRate = 1.3;
      newAudio.play();

      newAudio.onended = () => {
        clearRecorders();
        setTimeout(() => {
          startAudioRecording();
        }, 50);
      };
      setAudio?.(newAudio);
    }
  }, [currentQuestion, currentQuestionIndex, isCodingQuestion, isInFullScreen]);

  useEffect(() => {
    window.addEventListener("tab_switched", () => stopAudioRecording(true));
    window.addEventListener("tab_switch_reverted", () => startAudioRecording());
    window.addEventListener("entered_full_screen", () =>
      handleFullScreenEnter()
    );

    if (questions.length > 0) {
      setAllQuestions(questions);
      setChats?.([
        ...chats,
        {
          chat: questions[0].question,
          isUserChat: false
        }
      ]);
    }

    return () => {
      window.removeEventListener("tab_switched", () =>
        stopAudioRecording(true)
      );
      window.removeEventListener("tab_switch_reverted", () =>
        startAudioRecording()
      );
      window.removeEventListener("entered_full_screen", () =>
        handleFullScreenEnter()
      );
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (roomId) {
      socket.on("transcription", (data) => {
        setAnswer?.(data.transcript);
        if (data.room_id === roomId) {
          if (!chats[chatsLength - 1].isUserChat) {
            setChats?.([
              ...chats,
              {
                chat: data.transcript,
                isUserChat: true
              }
            ]);
          } else {
            const newChats = [...chats];
            newChats.pop();
            setChats?.([
              ...newChats,
              {
                chat: data.transcript,
                isUserChat: true
              }
            ]);
          }
        }
      });
    }
  }, [roomId]);

  useEffect(() => {
    const div = document.getElementById(
      "chats-container-for-user-interaction"
    )!;
    const id = setInterval(() => {
      div.scrollTop = div.scrollHeight;
    }, 500);

    setRoomId(uuid());

    socket.on("error", function (err) {
      console.log(err);
    });
    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <FullScreen
      className="fullScreenStyle"
      handle={fullScreenHandle}
      onChange={handleFullScreenChange}
    >
      {isCodingQuestion ? (
        <CodingRound
          question={currentQuestion.question}
          handleQuestionChange={handleQuestionChange}
          currentQuestionNumber={currentCodingQuestionIndex}
        />
      ) : (
        <main
          className="min-h-screen max-h-screen overflow-scroll"
          style={{
            background:
              "linear-gradient(126.11deg, #ECECFF 0%, #F2E9F0 35.83%, #F7E9E7 53.81%, #F5E4EE 76.96%, #FAE5F4 100%)"
          }}
        >
          <header
            className={`${interviewCommonStyles.behavior__header} border-b border-b-[#cacaca] px-6 h-24`}
          >
            <Row classNames="pt-4">
              <div className="w-2/5">
                <img
                  src={
                    "https://images.ctfassets.net/7oc31naqqojs/19IuRVsEWksBeu3EtMHkuL/86ca2c966980d64c0c4869aa03f1a312/Frame_1686552542.png"
                  }
                  alt="Logo"
                  style={{width: 235, height: 60}}
                />
              </div>
              <p className="w-2/5 font-bold text-4xl">
                {getMicrocopy(
                  isBehaviourQuestion
                    ? microCopies.INTERVIEW_ROUND_TWO_BEHAVIOURAL_ROUND_HEADING
                    : microCopies.INTERVIEW_ROUND_TWO_HEADING
                )}
              </p>
              <Row classNames="justify-end w-1/5">
                {currentQuestionType === "Technical_analysis" &&
                  !showPhaseOneCompletedSection && (
                    <div
                      className={`py-2 px-4 rounded-[45px] text-white-900 ${
                        technicalRoundRemainingTime > 180
                          ? "bg-[#28af3a]"
                          : "bg-[#f63333]"
                      }`}
                    >
                      <CountDownTimer
                        time={900}
                        returnUpdatedValue={handleTechnicalRoundTimerChange}
                        sx={{fontWeight: "bold"}}
                        onStopTimer={() => {
                          const a = allQuestions.findIndex(
                            (e) => e.overall_type === "behavioral_analysis"
                          );
                          setCurrentQuestionIndex(a);
                        }}
                      />
                    </div>
                  )}

                {currentQuestionType === "behavioral_analysis" &&
                  !showPhaseOneCompletedSection && (
                    <div
                      className={`py-2 px-4 rounded-[45px] text-white-900 ${
                        behaviouralRoundRemainingTime > 180
                          ? "bg-[#28af3a]"
                          : "bg-[#f63333]"
                      }`}
                    >
                      <CountDownTimer
                        time={420}
                        returnUpdatedValue={(reamaining) =>
                          setBehaviouralRoundRemainingTime(reamaining)
                        }
                        sx={{fontWeight: "bold"}}
                        onStopTimer={() => {
                          const a = allQuestions.findIndex(
                            (e) => e.overall_type === "coding_analysis"
                          );
                          setCurrentQuestionIndex(a);
                        }}
                      />
                    </div>
                  )}

                {currentQuestionType === "coding_analysis" && (
                  <CountDownTimer
                    time={1800}
                    sx={{fontWeight: "bold"}}
                    onStopTimer={stopRecording}
                  />
                )}
              </Row>
            </Row>
          </header>

          {currentQuestionType === "Technical_analysis" && (
            <Row classNames="justify-end mt-2 px-6 font-bold">{`Question - ${
              currentQuestionIndex + 1
            }/${numberOfTechnicalQuestions * 2}`}</Row>
          )}

          <div
            className={"flex mt-4 px-6"}
            style={{height: "calc(100vh - 8rem)"}}
          >
            <div className={`${styles.container}`}>
              <Row classNames={styles.wrapper}>
                <div className="w-full h-full flex">
                  <Row classNames={styles.own__feed}>
                    <section className="webcam__container relative w-full h-full">
                      <div className="min-h-full flex flex-col">
                        <Row
                          style={{
                            minHeight: +videoConstraints.height! / 1.2,
                            boxShadow: "0px 0px 22.98px 0px rgba(0, 0, 0, 0.1)",
                            overflow: "hidden",
                            position: "relative"
                          }}
                          classNames="bg-white-900 rounded-lg flex justify-center"
                        >
                          <div className={styles.logo_dynamic_bg} />
                          <img
                            src={Images.RecruitbaeLogoWithoutText}
                            alt="logo-without-text"
                            style={{
                              zIndex: 2
                            }}
                            className={`${styles.scalingImage} ${
                              !audio?.paused && styles.scalingImage_animation
                            }`}
                          />
                        </Row>

                        <div
                          className="relative mt-20 bg-blue-200"
                          style={{
                            width: "100%"
                          }}
                        >
                          <Webcam
                            className="rounded-lg"
                            pingedScreenshots={
                              isTechnicalQuestion || isBehaviourQuestion
                            }
                            pingInterval={10000}
                            onCaptureScreenshot={onCaptureScreenshot}
                          />
                        </div>
                      </div>
                    </section>
                  </Row>

                  {!showTimer && (
                    <div className="flex-0.6 h-full">
                      <div className={styles.questions__card}>
                        <div
                          className="rounded-lg pt-[18px] px-[24px] min-w-[97%] max-w-[97%] h-full relative bg-white-900 overflow-scroll"
                          id="chats-container-for-user-interaction"
                          style={{
                            boxShadow: "0px 0px 22.98px 0px rgba(0, 0, 0, 0.1)",
                            display: showPhaseOneCompletedSection
                              ? "flex"
                              : "block",
                            alignItems: showPhaseOneCompletedSection
                              ? "center"
                              : "flex-start",
                            justifyContent: showPhaseOneCompletedSection
                              ? "center"
                              : "flex-start"
                          }}
                        >
                          {showPhaseOneCompletedSection ? (
                            <section className="w-full flex items-center flex-col">
                              <p className="text-center font-bold text-xl">
                                {getMicrocopy(
                                  microCopies.INTERVIEW_PHASE1_COMPLETED_TITLE
                                )}
                              </p>
                              <p className="w-3/4 text-center mt-4 text-sm font-medium">
                                <span className="text-green-400">
                                  Congrats!{" "}
                                </span>
                                {getMicrocopy(
                                  microCopies.INTERVIEW_PHASE1_COMPLETED_MESSAGE
                                )}
                              </p>
                              <button
                                onClick={() => {
                                  setShowPhaseOneCompletedSection(false);
                                  setCurrentQuestionIndex((prev) => prev + 1);
                                }}
                                className="mt-6 bg-lightBlack-900 text-white-900 py-2 px-6 rounded-lg font-bold"
                              >
                                Continue to the coding exercise
                              </button>
                            </section>
                          ) : !isBehaviourQuestion ? (
                            chats.map(({chat, isUserChat}, index) => (
                              <div className="relative w-full mb-6">
                                <Row
                                  key={`chat-${index}`}
                                  classNames={`${
                                    isUserChat ? "flex-row-reverse" : "flex-row"
                                  }`}
                                >
                                  {isUserChat ? (
                                    <Row
                                      classNames="flex-row-reverse w-full !items-start"
                                      key={`user-chat-${index + 1}`}
                                    >
                                      <div
                                        className="rounded-full flex items-center justify-center bg-[#785DD2] text-white-900 font-bold p-3"
                                        style={{width: 20, height: 20}}
                                      >
                                        {interview_details?.data.assessment.candidate.user.first_name
                                          .charAt(0)
                                          .toUpperCase()}
                                      </div>
                                      <UserChat
                                        chat={chat}
                                        index={index}
                                        audioChunks={audioChunks}
                                        onEndTime={handleQuestionChange}
                                      />
                                    </Row>
                                  ) : (
                                    <Row
                                      classNames="w-full"
                                      key={`system-chat-${index + 1}`}
                                    >
                                      <div className="bg-[#FBE8FF] p-2 rounded-[50%]">
                                        <img
                                          src={Images.RecruitbaeLogoWithoutText}
                                          alt=""
                                          style={{width: 20, height: 20}}
                                        />
                                      </div>
                                      <SystemChat chat={chat} />
                                    </Row>
                                  )}
                                </Row>
                              </div>
                            ))
                          ) : (
                            <div
                              className={`flex flex-col justify-center h-[65vh] xxl:!pr-[60px] ${styles.frame_div}`}
                            >
                              <div>
                                <p className="flex gap-[6px] text-18 font-semibold leading-[21px]">
                                  <span>{currentBehaviourQuestionIndex}.</span>{" "}
                                  {currentQuestion.question}
                                </p>
                                <div className="mt-7 flex flex-col gap-5">
                                  <p className="text-14 font-medium -mb-2">
                                    Multiple options can be selected
                                  </p>
                                  {currentQuestion.options &&
                                    JSON.parse(currentQuestion.options).map(
                                      (opt: string) => (
                                        <div className="flex p-[10px] gap-[10px] rounded border border-[#FFD8F4] bg-[#e087c71a] mr-7 xxl:mr-[54px]">
                                          <Checkbox
                                            {...label}
                                            checked={selectedOptions.includes(
                                              opt
                                            )}
                                            onClick={() =>
                                              setSelectedOptions(
                                                (prevSelectedOptions) =>
                                                  prevSelectedOptions.includes(
                                                    opt
                                                  )
                                                    ? prevSelectedOptions.filter(
                                                        (option) =>
                                                          option !== opt
                                                      )
                                                    : [
                                                        ...prevSelectedOptions,
                                                        opt
                                                      ]
                                              )
                                            }
                                            sx={{
                                              color: "#00000033",
                                              width: "17px",
                                              height: "17px",
                                              borderRadius: "2px",
                                              background: "#fff",
                                              "&.Mui-checked": {
                                                background: "#8433A8",
                                                color: "#E6D6EE"
                                              }
                                            }}
                                          />
                                          <p className="text-16 font-medium leading-[19px] ">
                                            {opt}
                                          </p>
                                        </div>
                                      )
                                    )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="h-10% flex items-end justify-end">
                        {!showPhaseOneCompletedSection && (
                          <InterviewActionButton
                            isDarkMode
                            label=" Next Question"
                            handleClick={() => handleQuestionChange("")}
                            disabled={
                              (!isCodingQuestion &&
                                !isBehaviourQuestion &&
                                audio &&
                                !audio.paused) ||
                              disabled ||
                              processingResponse
                            }
                            isLoading={processingResponse}
                            otherStyles={`${
                              (!isCodingQuestion &&
                                !isBehaviourQuestion &&
                                audio &&
                                !audio.paused) ||
                              disabled ||
                              processingResponse
                                ? "disabled cursor-not-allowed"
                                : ""
                            }`}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </Row>
            </div>
          </div>
        </main>
      )}
    </FullScreen>
  );
};
