import PageLoader from "components/PageLoader";
import {APIEndPoints} from "globals/constants/endPoints";
import axiosGetModule from "network/Get/AxiosGetModule";
import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";

const ShortenedInterview = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const getInterviewURL = async () => {
    const {
      data: {url}
    } = await axiosGetModule(APIEndPoints.GET_INTERVIEW_URL(id!));

    const [_, trailingPath] = url.split(process.env.REACT_APP_DOMAIN);
    navigate(trailingPath);
  };

  useEffect(() => {
    getInterviewURL();
  }, []);
  return <PageLoader />;
};

export default ShortenedInterview;
