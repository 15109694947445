import {SxProps, Theme, Typography} from "@mui/material";
import React from "react";

interface CountDownTimerProps {
  time: number;
  sx?: SxProps<Theme>;
  showSeconds?: boolean;
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "button"
    | "overline"
    | "inherit";
  onStopTimer?: () => void;
  returnUpdatedValue?: (updated: number) => void;
  suffixText?: string;
}

interface CountDownTimerState {
  timer: number;
  startTime: number;
  interval?: NodeJS.Timeout;
}

export class CountDownTimer extends React.Component<
  CountDownTimerProps,
  CountDownTimerState
> {
  constructor(props: CountDownTimerProps) {
    super(props);
    this.state = {timer: props.time || 900, startTime: props.time || 900};
  }

  componentDidMount() {
    const interval = setInterval(() => {
      this.setState((prevState) => ({timer: prevState.timer - 1}));
      this.props.returnUpdatedValue &&
        this.props.returnUpdatedValue(this.state.timer);
    }, 1000);
    this.setState({interval});
  }

  componentDidUpdate() {
    if (this.state.timer === 0) {
      this.state.interval && clearInterval(this.state.interval);
      this.props.onStopTimer && this.props.onStopTimer();
    }
  }

  static getDerivedStateFromProps(
    nextProps: CountDownTimerProps,
    prevState: CountDownTimerState
  ) {
    if (nextProps.time !== prevState.startTime) {
      return {
        timer: nextProps.time,
        startTime: nextProps.time,
      };
    }
    return null;
  }

  componentWillUnmount() {
    this.state.interval && clearInterval(this.state.interval);
  }

  renderTime() {
    const minutes = Math.floor(this.state.timer / 60);
    const seconds = this.state.timer - minutes * 60;
    return `${minutes.toString().length < 2 ? "0" + minutes : minutes} : ${
      seconds.toString().length < 2 ? "0" + seconds : seconds
    }`;
  }
  render() {
    return (
      <Typography sx={this.props.sx} variant={this.props.variant}>
        {" "}
        {this.renderTime()} {this.props.suffixText}
      </Typography>
    );
  }
}
