import {Backdrop} from "@mui/material";
import {AppColors} from "globals/appcolors";
import {Spinner} from "react-activity";

const PageLoader = () => {
  return (
    <Backdrop open>
      <Spinner size={40} color={AppColors.PRIMARYCOLOR} />
    </Backdrop>
  );
};

export default PageLoader;
