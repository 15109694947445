import NotFound from "./404NotFound.gif";
import EmptyQuestions from "./EmptyQuestions.svg";
import InterviewFlash from "./InterviewFlash.svg";
import CallIcon from "./InterviewIntruction/call-icon.svg";
import InterviewVideoBg from "./InterviewIntruction/interview-video-bg.png";
import RecordingIcon from "./InterviewIntruction/recording-icon.svg";
import TimerIcon from "./InterviewIntruction/timer.svg";
import InterviewInstruction from "./Interview_instruction.svg";
import Search from "./Search.svg";
import InterviewFinish from "./ThankYouScreen.svg";
import BackArrow from "./back-arrow.svg";
import BehaviourIcon from "./behaviourTestIcon.png";
import bottomBg from "./bottom-bg-gen-pass.svg";
import Cross from "./cross.svg";
import Dustbin from "./dustbin.svg";
import EditIcon from "./editIcon.svg";
import FileUploadArea from "./fileUploadArea.png";
import loader2 from "./loader2.gif";
import loginBg from "./login-bg.svg";
import SourceBaeLogo from "./logo.svg";
import LogoWithoutText from "./logoWithoutText.svg";
import NewNoAssessments from "./new_no_assessment.svg";
import NoAssessments from "./no_assessments.svg";
import NoTemplates from "./no_templates.svg";
import OutlinedFlag from "./outlinedFlag.svg";
import ProfilePlaceholder from "./profile_placeholder.png";
import QuestionBanner from "./questionBanner.svg";
import Recording from "./recording.svg";
import ReportCardCalendar from "./report-card/calender.png";
import ReportCardCTAArrow from "./report-card/ctaArrow.png";
import ReportCardEmail from "./report-card/emailIcon.png";
import ReportCardFigma from "./report-card/figma.png";
import ReportCardGithub from "./report-card/github.png";
import ReportCardHeaderBG from "./report-card/heroBackground.jpeg";
import ReportCardLinkedin from "./report-card/linkedin.png";
import ReportCardLogo from "./report-card/logo.png";
import ReportCardMap from "./report-card/mapIcon.png";
import ReportCardPhone from "./report-card/phone.png";
import ReportCardWeb from "./report-card/web.png";
import Sandbox from "./sandbox.gif";
import logo from "./sourcebae-cropped.svg";
import StepsForAssessment from "./steps-assessment.png";
import StepsForIntro from "./steps-intro.png";
import ViewEye from "./viewEye.svg";

export const Images = {
  EmptyQuestions,
  QuestionBanner,
  OutlinedFlag,
  Recording,
  EditIcon,
  NotFound,
  Dustbin,
  loader2,
  Search,
  Cross,
  bottomBg,
  logo,
  LogoWithoutText,
  loginBg,
  ViewEye,
  BackArrow,
  ProfilePlaceholder,
  InterviewFlash,
  InterviewFinish,
  NoAssessments,
  NewNoAssessments,
  NoTemplates,
  SourceBaeLogo,
  InterviewInstruction,
  InterviewVideoBg,
  TimerIcon,
  RecordingIcon,
  CallIcon,
  Sandbox,
  Document,
  Screen,
  BehaviourIcon,
  StepsForAssessment,
  StepsForIntro,
  RecruitbaeLogoWithoutText:
    "https://images.ctfassets.net/7oc31naqqojs/1fnMNwUOAVhRnibO1l0QGL/2b824c050063cb19ee53a7ebba3f3806/orange_pink-View_12_4.png",
  FileUploadArea,
  ReportCardCalendar,
  ReportCardCTAArrow,
  ReportCardEmail,
  ReportCardFigma,
  ReportCardHeaderBG,
  ReportCardLinkedin,
  ReportCardLogo,
  ReportCardMap,
  ReportCardPhone,
  ReportCardWeb,
  ReportCardGithub
};
