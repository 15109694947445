import {Row} from "components/Row";
import {useFetchAssessmentDetails} from "hooks/interview/useFetchAssessmentDetails";
import {Images} from "Images";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

const InterviewScreenshots = () => {
  const {assessmentId} = useParams();

  const {refetch: getAssessmentDetails} = useFetchAssessmentDetails(
    assessmentId!
  );

  const [screenshots, setScreenshots] = useState<string[]>([]);

  useEffect(() => {
    getAssessmentDetails().then(async ({data}) => {
      setScreenshots(data!.data.assessment.screenshots.map((e) => e.path));
    });
  }, []);

  return (
    <div className="p-12">
      <Row classNames="justify-center mb-10">
        <img
          src={Images.RecruitbaeLogoWithoutText}
          alt=""
          style={{width: 70, height: 70, textAlign: "center"}}
        />
        <p className="font-bold text-3xl ml-2">Recruitbae</p>
      </Row>
      <Row classNames="flex-wrap">
        {screenshots.length > 0 &&
          screenshots.map((screenshot) => {
            return <img className="mb-8 rounded mr-20" src={screenshot} />;
          })}
      </Row>
    </div>
  );
};

export default InterviewScreenshots;
