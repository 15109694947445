import {
  constantsQuery,
  secondRoundMicrocopiesQuery,
} from "globals/constants/queries/secondRoundInstructions";
import {
  generateGetMicroCopy,
  getContentfulData,
  strToBool,
} from "globals/helpers";
import {
  AssessmentResponse,
  AssessmentTemplateResponse,
  InterviewInstructionsResponse,
} from "globals/types/APIResponses";
import {
  AssessmentData,
  AssessmentTemplateData,
  Constant,
  CreateAssessmentValues,
  DateType,
  GetAllQuestionDataNode,
  GetMicroCopy,
  Microcopy,
  Permissions,
  Site,
} from "globals/types/globalTypes";
import {createContext, useContext, useEffect, useState} from "react";

export interface AppCommonDataContextProps {
  assessments_timestamp: DateType;
  setAssessments_timestamp: ((value: DateType) => void) | null;
  assessments: AssessmentResponse | null;
  setAssessments: ((assessments: AssessmentResponse | null) => void) | null;
  loading: boolean | null;
  setLoading: ((loading: boolean) => void) | null;
  assessments_template_timestamp: DateType;
  setAssessments_template_timestamp: ((value: DateType) => void) | null;
  assessmentTemplates: AssessmentTemplateResponse | null;
  setAssessmentTemplates:
    | ((assessments: AssessmentTemplateResponse | null) => void)
    | null;
  questionData: GetAllQuestionDataNode | null;
  setquestionData: ((value: GetAllQuestionDataNode | null) => void) | null;
  assessment_details: AssessmentData | AssessmentTemplateData | null;
  setAssessment_details:
    | ((details: AssessmentData | AssessmentTemplateData | null) => void)
    | null;
  active_navbar_index: number;
  setActive_navbar_index: ((value: number) => void) | null;
  interview_details: InterviewInstructionsResponse | null;
  setInterview_details:
    | ((value: InterviewInstructionsResponse | null) => void)
    | null;
  newInterviewsPageCount: {count: number; hasReachedLast?: boolean};
  setnewInterviewsPageCount:
    | ((value: {count: number; hasReachedLast?: boolean}) => void)
    | null;
  oldInterviewsPageCount: {count: number; hasReachedLast?: boolean};
  setoldInterviewsPageCount:
    | ((value: {count: number; hasReachedLast?: boolean}) => void)
    | null;
  allQuestions: GetAllQuestionDataNode[] | null;
  setAllQuestions:
    | ((questions: GetAllQuestionDataNode[] | null) => void)
    | null;
  totalNumberOfQuestions: number | null;
  setTotalNumberOfQuestions: ((number: number | null) => void) | null;
  scrollOffset: number | null;
  setScrollOffset: ((offset: number | null) => void) | null;
  permissions: Permissions | null;
  setPermissions: ((permissions: Permissions | null) => void) | null;
  createAssesmentValues: CreateAssessmentValues | null;
  setCreateAssesmentValues:
    | ((values: CreateAssessmentValues | null) => void)
    | null;
  testForcefullyFinished: boolean | null;
  setTestForcefullyFinished: ((status: boolean | null) => void) | null;
  getMicrocopy: GetMicroCopy;
  siteConstants: Constant[];
}

export const AppCommonDataContext = createContext<AppCommonDataContextProps>({
  assessments_timestamp: null,
  setAssessments_timestamp: null,
  assessments: null,
  setAssessments: null,
  loading: null,
  setLoading: null,
  assessments_template_timestamp: null,
  setAssessments_template_timestamp: null,
  assessmentTemplates: null,
  setAssessmentTemplates: null,
  questionData: null,
  setquestionData: null,
  assessment_details: null,
  setAssessment_details: null,
  active_navbar_index: 0,
  setActive_navbar_index: null,
  interview_details: null,
  setInterview_details: null,
  newInterviewsPageCount: {count: 0, hasReachedLast: false},
  setnewInterviewsPageCount: null,
  oldInterviewsPageCount: {count: 0, hasReachedLast: false},
  setoldInterviewsPageCount: null,
  allQuestions: null,
  setAllQuestions: null,
  totalNumberOfQuestions: null,
  setTotalNumberOfQuestions: null,
  scrollOffset: null,
  setScrollOffset: null,
  permissions: null,
  setPermissions: null,
  createAssesmentValues: null,
  setCreateAssesmentValues: null,
  testForcefullyFinished: null,
  setTestForcefullyFinished: null,
  getMicrocopy: (path) => `MC: ${path}`,
  siteConstants: [],
});

export const AppCommonDataProvider = (props: {
  children: React.ReactElement;
}) => {
  const [assessments_timestamp, setAssessments_timestamp] =
    useState<AppCommonDataContextProps["assessments_timestamp"]>(null);
  const [assessments, setAssessments] =
    useState<AppCommonDataContextProps["assessments"]>(null);
  const [loading, setLoading] =
    useState<AppCommonDataContextProps["loading"]>(null);
  const [assessments_template_timestamp, setAssessments_template_timestamp] =
    useState<AppCommonDataContextProps["assessments_template_timestamp"]>(null);
  const [assessmentTemplates, setAssessmentTemplates] =
    useState<AppCommonDataContextProps["assessmentTemplates"]>(null);
  const [questionData, setquestionData] =
    useState<GetAllQuestionDataNode | null>(null);
  const [assessment_details, setAssessment_details] =
    useState<AppCommonDataContextProps["assessment_details"]>(null);
  const [active_navbar_index, setActive_navbar_index] = useState<number>(0);
  const [interview_details, setInterview_details] =
    useState<AppCommonDataContextProps["interview_details"]>(null);
  const [newInterviewsPageCount, setnewInterviewsPageCount] = useState<{
    count: number;
    hasReachedLast?: boolean;
  }>({count: 1, hasReachedLast: false});
  const [oldInterviewsPageCount, setoldInterviewsPageCount] = useState<{
    count: number;
    hasReachedLast?: boolean;
  }>({count: 1, hasReachedLast: false});
  const [allQuestions, setAllQuestions] =
    useState<AppCommonDataContextProps["allQuestions"]>(null);
  const [totalNumberOfQuestions, setTotalNumberOfQuestions] =
    useState<AppCommonDataContextProps["totalNumberOfQuestions"]>(null);
  const [scrollOffset, setScrollOffset] =
    useState<AppCommonDataContextProps["scrollOffset"]>(null);
  const [permissions, setPermissions] =
    useState<AppCommonDataContextProps["permissions"]>(null);
  const [createAssesmentValues, setCreateAssesmentValues] =
    useState<AppCommonDataContextProps["createAssesmentValues"]>(null);
  const [testForcefullyFinished, setTestForcefullyFinished] =
    useState<AppCommonDataContextProps["testForcefullyFinished"]>(null);
  const [microcopies, setMicrocopies] = useState<Microcopy[]>([]);
  const [siteConstants, setSiteConstants] = useState<
    AppCommonDataContextProps["siteConstants"]
  >([]);

  const fetchMicrocopies = async () => {
    const {
      data: {
        siteCollection: {items},
      },
    } = await getContentfulData<{
      siteCollection: {items: [Pick<Site, "microcopiesCollection">]};
    }>(secondRoundMicrocopiesQuery, {
      project: process.env.REACT_APP_CONTENTFUL_PROJECT,
    });

    setMicrocopies(items[0].microcopiesCollection.items);
  };

  const fetchConstants = async () => {
    const {
      data: {
        siteCollection: {items},
      },
    } = await getContentfulData<{
      siteCollection: {items: Array<Pick<Site, "constantsCollection">>};
    }>(constantsQuery, {
      project: process.env.REACT_APP_CONTENTFUL_PROJECT,
    });

    setSiteConstants(items[0].constantsCollection.items);
  };

  useEffect(() => {
    if (strToBool(process.env.REACT_APP_ENABLE_CONTENTFUL_FETCH!)) {
      fetchMicrocopies();
      fetchConstants();
    }
  }, []);

  const getMicroCopy = generateGetMicroCopy(microcopies);

  return (
    <AppCommonDataContext.Provider
      value={{
        assessments_timestamp,
        setAssessments_timestamp,
        assessments,
        setAssessments,
        loading,
        setLoading,
        assessments_template_timestamp,
        setAssessments_template_timestamp,
        assessmentTemplates,
        setAssessmentTemplates,
        questionData,
        setquestionData,
        assessment_details,
        setAssessment_details,
        active_navbar_index,
        setActive_navbar_index,
        interview_details,
        setInterview_details,
        newInterviewsPageCount,
        setnewInterviewsPageCount,
        oldInterviewsPageCount,
        setoldInterviewsPageCount,
        allQuestions,
        setAllQuestions,
        totalNumberOfQuestions,
        setTotalNumberOfQuestions,
        scrollOffset,
        setScrollOffset,
        permissions,
        setPermissions,
        createAssesmentValues,
        setCreateAssesmentValues,
        testForcefullyFinished,
        setTestForcefullyFinished,
        getMicrocopy: getMicroCopy,
        siteConstants,
      }}
    >
      {props.children}
    </AppCommonDataContext.Provider>
  );
};

export const useAppCommonDataProvider = () =>
  useContext<AppCommonDataContextProps>(AppCommonDataContext);
