import {
  ConstantsFragment,
  InstructionsFragment,
  MicroCopyFragment,
} from "../contentfulFragments";

export const secondRoundInstructionsQuery = `
query($project: String!) {
    siteCollection(where: {project: $project}) {
        ...on SiteCollection {
            items {
                instructionsCollection {
                    ...InstructionsFragment
                }
            }
        }
    }
}
${InstructionsFragment}
${MicroCopyFragment}
`;

export const secondRoundMicrocopiesQuery = `
query($project: String!) {
    siteCollection(where: {project: $project}) {
        ...on SiteCollection {
            items {
                microcopiesCollection {
                    items{
                        ...MicroCopyFragment
                    }
                }
            }
        }
    }
}
${MicroCopyFragment}
`;

export const constantsQuery = `
query($project: String!) {
    siteCollection(where: {project: $project}) {
        ...on SiteCollection {
            items {
                constantsCollection {
                    items {
                        ...ConstantsFragment
                    }
                }
            }
        }
    }
}
${ConstantsFragment}
`;
